:root {
  --main-color: #0075bf;
  --main-color-rgb: 0, 117, 191; /* same as --main-color, but some functions does not accept HEX */
  --main-color-hover: rgba(var(--main-color-rgb), 0.15);
}

a {
  color: var(--main-color);
}

a.clear {
  text-decoration: none !important;
}

a.clickable {
  cursor: pointer;
  border-bottom: 1px dashed;
}

a.clickable:hover {
  border-bottom: 1px dashed;
  text-decoration: none !important;
}

body {
  background-color: #fcfcfc;
}

.bg-blue {
  background-color: var(--main-color) !important;
}

.bg-welcome {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  background-image: url("./assets/img/login-background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  filter: blur(2px);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
}

.border-blue {
  border-color: var(--main-color) !important;
}

.border-none {
  border: none;
}

.border-2 {
  border-width:2px !important;
}

.btn-blue {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: white;
}

.btn-blue:hover {
  color: white;
  /* background-color: var(--main-color-hover); */
}

.btn-outline-toggle {
  border-color: var(--main-color) !important;
  color: var(--main-color) !important;
  
}

.btn-outline-toggle:hover {
  background-color: var(--main-color-hover) !important;
  color: var(--main-color) !important;
}

.btn-outline-toggle:not(:disabled):not(.disabled):active,
.btn-outline-toggle:not(:disabled):not(.disabled).active,
.show>.btn-outline-toggle.dropdown-toggle {
    color: #fcfcfc !important;
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: var(--main-color);
  background-color: var(--main-color);
}

div.paging-wrapper {
  height: fit-content;
}

div.paging-wrapper > .pagination-size {
  width: auto;
}

div.paging-wrapper > .pagination-control {
  justify-content: center !important;
  display: flex !important;
  height: fit-content;
}

div.paging-wrapper > .pagination-control > ul {
  margin-bottom: 0 !important;
}

div.paging-wrapper > span.pagination-index {
  height: fit-content; 
  padding: 0.375rem 1rem 0.375rem 1rem; 
  color: #495057;
  border-radius: 5px;
}

.flag {
  height: 2rem; 
 }

.i-calendar::before {
  content: '📅';
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.pass {
  -webkit-text-security: disc;
  -moz-text-security: disc;
  -text-security: disc;
}

#please-wait {
  position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
  background:rgba(0, 0, 0, .15);
}

.pointer {
  cursor: pointer;
}

.pre-line {
  white-space: pre-line;
}

.pre-wrap {
  white-space: pre-wrap;
}

.required-field {
  color: red;
  font-size: 12pt;
  font-weight: bold;
  margin-right: 2pt;
}

.table-nostriped tbody tr:nth-of-type(odd) {
  background-color:transparent;
}

.text-blue {
  color: var(--main-color) !important;
}

.text-lines-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

th[sortBy].desc:before,
th[sortBy].asc:before {
    content: '';
    display: block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC) no-repeat;
    background-size: 22px;
    width: 22px;
    height: 22px;
    float: right;
    margin-right: -11px;
}

th[sortBy].desc:before {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}

.w-logo {
  width: 2rem !important;
}

.w-min {
  width: 1%;
  white-space: nowrap;
}

.w-30 {
  width: 30%!important;
}

.w-40 {
  width: 40%!important;
}

.custom-button {
  height: calc(1.5em + .75rem + 2px);
}
